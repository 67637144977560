import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../components/fb/fb";

const PASS = 'pets2025!'

export default function Register() {
  const [pets, setPets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [pass, setPass] = useState('');
  const [newPet, setNewPet] = useState({
    id: "",
    name: "",
    raza: "",
    gender: "",
    chip: "",
    photo: "",
  });
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetchPets();
  }, []);

  const fetchPets = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "PETS"));
      const petsArray = querySnapshot.docs.map((pet) => {
        return {
          idDoc: pet.id,
          ...pet.data(),
        };
      });
      setPets(petsArray);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener las mascotas:", error);
    }
  };

  const handleInputChange = (e) => {
    setNewPet({ ...newPet, [e.target.name]: e.target.value });
  };

  const uploadImageToCloudinary = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pets-files");
    formData.append("folder", "pets");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlqfughl7/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setUploading(false);
      return data.secure_url;
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      setUploading(false);
      return null;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      const imageUrl = await uploadImageToCloudinary(file);
      setNewPet({ ...newPet, photo: imageUrl });
    }
  };

  const handleAddPet = async () => {
    if (!newPet.photo) {
      alert("Por favor, sube una imagen.");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "PETS"), newPet);
      setPets([...pets, { id: docRef.id, ...newPet }]);
      setNewPet({
        id: "",
        idDoc: "",
        name: "",
        raza: "",
        gender: "",
        chip: "",
        photo: "",
      });
      setPreview(null);
    } catch (error) {
      console.error("Error al agregar la mascota:", error);
    }
  };

  const handleDeletePet = async (id) => {
    try {
      await deleteDoc(doc(db, "PETS", id));
      setPets(pets.filter((pet) => pet.idDoc !== id));
    } catch (error) {
      console.error("Error al eliminar la mascota:", error);
    }
  };

  const isValidOC = () => {
    if (pass === PASS) {
        setIsValid(true);
    } else {
        setIsValid(false);
        alert('Dato incorrecto!')
    }
  }

  if (!isValid) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <input
                style={styles.input}
                type="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder="password"
                className="input"
            />
            <button  style={styles.button} type="submit" className="button" onClick={isValidOC}>Ingresar</button>
    </div>
    )
  }

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2>Agregar Mascota</h2>
        <input
          style={styles.input}
          type="text"
          name="id"
          placeholder="ID"
          value={newPet.id}
          onChange={handleInputChange}
        />
        <input
          style={styles.input}
          type="text"
          name="name"
          placeholder="Nombre"
          value={newPet.name}
          onChange={handleInputChange}
        />
        <input
          style={styles.input}
          type="text"
          name="raza"
          placeholder="Raza"
          value={newPet.raza}
          onChange={handleInputChange}
        />
        <input
          style={styles.input}
          type="text"
          name="gender"
          placeholder="Género"
          value={newPet.gender}
          onChange={handleInputChange}
        />
        <input
          style={styles.input}
          type="text"
          name="chip"
          placeholder="Chip"
          value={newPet.chip}
          onChange={handleInputChange}
        />
        <input
          style={styles.input}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          disabled={uploading}
        />
        {preview && (
          <img src={preview} alt="Vista previa" style={styles.preview} />
        )}
        <button
          style={styles.button}
          onClick={handleAddPet}
          disabled={uploading}
        >
          Agregar Mascota
        </button>
      </div>
      <div style={styles.tableContainer}>
        <h2>Lista de Mascotas</h2>
        {loading ? (
          <p>Cargando mascotas...</p>
        ) : (
          <table style={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Foto</th>
                <th>Nombre</th>
                <th>Raza</th>
                <th>Género</th>
                <th>Chip</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pets.map((pet) => (
                <tr key={pet.id}>
                  <td>{pet.id}</td>
                  <td>
                    {pet.photo && (
                      <img
                        src={pet.photo}
                        alt={pet.name}
                        style={styles.thumbnail}
                      />
                    )}
                  </td>
                  <td>{pet.name}</td>
                  <td>{pet.raza}</td>
                  <td>{pet.gender}</td>
                  <td>{pet.chip}</td>
                  <td>
                    <button onClick={() => handleDeletePet(pet.idDoc)}>
                      ❌ Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
    fontFamily: "Arial, sans-serif",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    borderRadius: "10px",
    background: "#f9f9f9",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "2px solid #ccc",
    outline: "none",
    transition: "border-color 0.2s",
  },
  inputFocus: {
    borderColor: "#007BFF",
  },
  fileInput: {
    padding: "5px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#007BFF",
    color: "white",
    cursor: "pointer",
    transition: "background 0.3s",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  tableContainer: {
    flex: 2,
    padding: "20px",
    borderRadius: "10px",
    background: "#ffffff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    textAlign: "left",
  },
  th: {
    background: "#007BFF",
    color: "white",
    padding: "10px",
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  thumbnail: {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  preview: {
    width: "200px",
    height: "200px",
    objectFit: "cover",
    borderRadius: "10px",
    marginTop: "10px",
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "14px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#FF4D4D",
    color: "white",
    cursor: "pointer",
    transition: "background 0.3s",
  },
  deleteButtonHover: {
    backgroundColor: "#cc0000",
  },
};
