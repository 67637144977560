import { collection , query, where, getDocs} from "firebase/firestore";
import { db } from "../fb/fb";

const getInfoCertificate = async (id, name) => {
    const miColeccion = collection(db, "PETS");
  
    const q = query(
      miColeccion,
      where("name", "==", name.toUpperCase()),
      where("id", "==", id.toUpperCase())
    );
  
    const querySnapshot = await getDocs(q);
  
    // Si no se encuentran documentos, retornamos null
    if (querySnapshot.empty) {
      return null;
    }
  
    // Si encontramos documentos, retornamos los datos del primer documento
    let result = null;
    querySnapshot.forEach((doc) => {
      result = doc.data(); // Guardamos los datos del primer documento encontrado
    });
  
    return result;
  };

export {
    getInfoCertificate
}