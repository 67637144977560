import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {

    const navigate = useNavigate();

      const handleClick = (path) => {
        if (window.location.pathname === path) {
            // Forzar recarga de la página
            window.location.reload();
        } else {
            navigate(path); // Navegar a la ruta normalmente
        }
    };

    return (
        <>
            <HashLink className="px-4 font-extrabold text-orange-400 hover:text-gray-800" to="/#hero" smooth scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                Inicio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-orange-400 hover:text-gray-800" to="/#about" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                Nosotros
            </HashLink>
            <HashLink className="px-4 font-extrabold text-orange-400 hover:text-gray-800" to="/#services" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                Servicio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-orange-400 hover:text-gray-800" to="/contact">
                Contacto
            </HashLink>
            <button className="text-white bg-orange-400 hover:bg-gray-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth onClick={() => handleClick('/certificate')}>
               Validar certificado
            </button>
        </>
    )
}

export default NavLinks;
