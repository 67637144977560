import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


export const firebaseConfig = {
    apiKey: "AIzaSyCn0kuk6...",
    authDomain: "cambiando-app.firebaseapp.com",
    projectId: "cambiando-app",
    storageBucket: "cambiando-app.appspot.com",
    messagingSenderId: "719182705855",
    appId: "1:719182705855:web:c1446587ec6d4bb930820a",
  };
  

  export const app = initializeApp(firebaseConfig);
  export  const db = getFirestore(app);